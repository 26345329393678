.dx-tab {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    padding: 8px 35px;
    margin-right: -5px;
    margin-left: -5px;
    box-shadow: 0 6px 0 -5px rgba(#000, .06), 0 -6px 0 -5px rgba(#000, .06);

    @media screen and #{$media_xl} {
        padding: 8px 40px;
    }
    @media screen and #{$media_md} {
        padding: 8px 20px;
    }

    > li {
        position: relative;
        display: inline-block;
        padding: 0 5px;
        font-size: .9375rem;
        font-weight: 600;
        letter-spacing: normal;

        a,
        button {
            position: relative;
            display: block;
            width: 100%;
            padding: 10px 15px;
            color: rgba($color_dark_1, .7);
            text-align: left;
            text-decoration: none;
            transition: color .2s ease-in-out;

            @media screen and #{$media_xl} {
                padding: 10px 8px;
            }

            > .icon {
                margin-left: 4px;
                opacity: .9;
            }

            &::after {
                content: "";
                position: absolute;
                right: 5px;
                bottom: -10px;
                left: 5px;
                height: 2px;
                background-color: $color_main_1;
                opacity: 0;
                will-change: opacity;
                transition: opacity .2s ease-in-out;

                @media screen and #{$media_md} {
                    bottom: 0;
                }
            }

            &.active,
            &:hover {
                color: $color_dark_1;
                text-decoration: none;
            }
            &.active::after {
                opacity: 1;
            }
        }
        button {
            font-weight: inherit;
            cursor: pointer;
            background: none;
            border: 0;
            box-shadow: none;
        }
    }
}

// Style Payment
.dx-tab-payment {
    padding: 0;
    border: 0;
    box-shadow: none;

    > li {
        padding: 0;

        > a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            padding: 16px;
            border-radius: 3px;
            box-shadow: inset 0 0 0 1px rgba(lighten($color_grey_3, 17%), .8);
            transition: box-shadow .2s ease-in-out;

            &.active {
                box-shadow: inset 0 0 0 2px $color_main_1;

                img {
                    filter: grayscale(0);
                    opacity: 1;
                }
                .dx-tab-payment-method-text {
                    opacity: 1;
                }
            }

            img {
                max-width: 100%;
                filter: grayscale(100%);
                opacity: .7;
                will-change: opacity;
                transition: opacity .2s ease-in-out, filter .2s ease-in-out;

                + .dx-tab-payment-method-text {
                    margin-left: 20px;
                }
            }

            .dx-tab-payment-method-text {
                font-family: $font_title;
                font-size: 12px;
                color: $color_dark_1;
                text-transform: uppercase;
                opacity: .7;
                will-change: opacity;
                transition: opacity .2s ease-in-out;
            }

            &::after {
                display: none;
            }
        }
    }
}

// Pane
.dx-tab-pane {
    position: absolute;

    &.active {
        position: static;
    }
}
