.dx-signin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 20px;
    font-size: .875rem;

    a:not(.dx-btn) {
        color: $color_grey_3;
        transition: color .2s ease-in-out;

        &:hover,
        &:focus {
            color: #fff;
            text-decoration: none;
        }
    }

    .dx-signin-content {
        width: 370px;
        max-width: 100%;
    }
    .dx-signin-or {
        display: flex;
        align-items: center;
        padding: 10px 0;
        font-family: $font_title;
        font-size: .75rem;
        font-weight: 500;
        color: $color_grey_3;

        &::before,
        &::after {
            content: "";
            display: block;
            flex-grow: 1;
            height: 1px;
            background-color: #282828;
        }
        &::before {
            margin-right: 25px;
        }
        &::after {
            margin-left: 25px;
        }
    }
}
