.dx-ticket {
    &.dx-ticket-open .dx-ticket-status,
    &.dx-ticket-closed .dx-ticket-status {
        &::before {
            content: "";
            width: 5px;
            min-width: 5px;
            height: 5px;
            margin-top: -2px;
            margin-right: 10px;
            border-radius: 5px;

            @media screen and #{$media_sm} {
                margin: 7px 0 0;
            }
        }
        &::after {
            font-family: $font_title;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;

            @media screen and #{$media_sm} {
                display: none;
            }
        }
    }
    &.dx-ticket-open {
        .dx-ticket-status {
            &::before {
                background-color: $color_main_1;
            }
            &::after {
                content: "Open";
                color: $color_main_1;
            }
        }
        .dx-ticket-title {
            padding-right: 40px;

            @media screen and #{$media_md} {
                padding-right: 60px;
            }
            @media screen and #{$media_sm} {
                padding-right: 30px;
            }
        }
    }
    &.dx-ticket-closed {
        .dx-ticket-status {
            &::before {
                background-color: #999;
            }
            &::after {
                content: "Closed";
                color: #999;
            }
        }
        .dx-ticket-title {
            padding-right: 60px;

            @media screen and #{$media_md} {
                padding-right: 70px;
            }
            @media screen and #{$media_sm} {
                padding-right: 30px;
            }
        }
    }

    .dx-ticket-status {
        position: absolute;
        display: flex;
        align-items: center;
        top: 30px;
        right: 30px;
        margin-left: 30px;
    }
}

// Item
.dx-ticket-item {
    position: relative;
    display: flex;
    padding: 30px;

    @media screen and #{$media_sm} {
        flex-direction: column;
    }

    + .dx-ticket-item {
        margin-top: 30px;
    }

    &.dx-ticket-new {
        .dx-ticket-info .dx-ticket-new {
            display: inline-block;
            padding: 2px 8px;
            margin-top: 4px;
            font-family: $font_title;
            font-size: 9px;
            color: #fff;
            text-transform: uppercase;
            background-color: $color_main_1;
            border-radius: 3px;
        }
    }
    &.dx-ticket-open .dx-ticket-status,
    &.dx-ticket-closed .dx-ticket-status {
        font-family: $font_title;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;

        &::before {
            content: "";
            width: 5px;
            min-width: 5px;
            height: 5px;
            margin-right: 10px;
            border-radius: 5px;
        }
        @media screen and #{$media_md} {
            position: absolute;
            top: 25px;
            right: 30px;
            margin: 0;
        }
    }
    &.dx-ticket-open .dx-ticket-status {
        color: $color_main_1;

        &::before {
            background-color: $color_main_1;
        }
    }
    &.dx-ticket-closed .dx-ticket-status {
        color: #999;

        &::before {
            background-color: #999;
        }
    }

    .dx-ticket-status {
        display: flex;
        align-items: center;
        margin-left: 30px;
    }

    .dx-ticket-cont {
        width: 100%;
    }
    .dx-ticket-img {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        min-width: 80px;
        height: 80px;
        overflow: hidden;
        border-radius: 3px;

        + .dx-ticket-cont {
            margin-left: 30px;

            @media screen and #{$media_sm} {
                margin-top: 30px;
                margin-left: 0;
            }
        }

        img {
            width: 100%;
            height: 100%;
            // stylelint-disable-next-line
            font-family: "object-fit: cover";
            object-fit: cover;
            border-radius: 3px;
        }
    }
    .dx-ticket-name {
        display: block;
        margin-top: -6px;
        font-weight: 600;
        color: $color_grey_1;

        + .dx-ticket-title {
            margin-top: 9px;
        }
    }
    .dx-ticket-title {
        display: block;
        margin-bottom: 0;
        transition: color .2s ease-in-out;

        + .dx-ticket-info {
            margin-top: 10px;
        }
    }
    .dx-ticket-info {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 0;
        margin: 0 0 -6px;
        font-size: .875rem;
        color: $color_grey_1;

        > li {
            display: inline-block;
            padding-top: 4px;
            margin-right: 30px;
            list-style: none;

            @media screen and #{$media_sm} {
                margin-right: 20px;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &:hover,
    &.hover {
        text-decoration: none;

        .dx-ticket-title {
            color: $color_main_1;
        }
    }
    &:focus,
    &.focus {
        text-decoration: none;
    }
}

// Comment
.dx-ticket-comment {
    padding: 50px;

    + .dx-ticket-comment {
        padding: 50px;
        margin-top: 0;
    }

    @media screen and #{$media_md} {
        padding: 30px;

        + .dx-ticket-comment {
            padding: 30px;
        }
    }


    &.dx-comment-replied {
        box-shadow: inset 3px 0 0 0 #dbdbdb, inset 0 1px 0 0 $color_grey_5, inset 0 -1px 0 0 $color_grey_5;

        .dx-comment-name .dx-comment-replied {
            margin-left: 3px;
            color: $color_grey_1;

            @media screen and #{$media_sm} {
                display: none;
            }
        }
    }
    &.dx-comment-new .dx-comment-name {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;

        @media screen and #{$media_sm} {
            display: flex;
            padding-right: 40px;
        }

        .dx-comment-new {
            position: relative;
            display: inline-block;
            order: 12;
            padding: 2px 8px;
            margin-left: 25px;
            font-family: $font_title;
            font-size: 9px;
            color: #fff;
            text-transform: uppercase;
            background-color: $color_main_1;
            border-radius: 3px;

            @media screen and #{$media_sm} {
                position: absolute;
                right: 0;
                margin: 0;
            }
        }
    }

    .dx-comment-text + .dx-comment-file {
        margin-top: 30px;
    }

    .dx-comment-file {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 20px 20px 20px 70px;
        color: rgba($color_grey_1, .8);
        background-color: rgba(lighten($color_grey_3, 24%), .8);
        border-radius: 3px;
        box-shadow: inset 0 0 0 1px rgba(lighten($color_grey_3, 17%), .8);
        transition: background-color .2s ease-in-out, box-shadow .2s ease-in-out, color .2s ease-in-out;

        .dx-comment-file-img {
            position: absolute;
            top: 12px;
            left: 12px;
        }
        .dx-comment-file-name {
            display: block;
            margin-top: -8px;
            font-size: .875rem;
        }
        .dx-comment-file-size {
            display: block;
            margin-top: -1px;
            margin-bottom: -6px;
            font-size: .875rem;
            color: $color_grey_3;
        }
        .dx-comment-file-icon {
            position: absolute;
            display: flex;
            align-items: center;
            top: 0;
            right: 20px;
            bottom: 0;
            font-size: 1.5rem;
        }

        &:hover,
        &.hover {
            color: darken($color_grey_1, 10%);
            text-decoration: none;
            background-color: lighten($color_grey_3, 22%);
            box-shadow: inset 0 0 0 1px lighten($color_grey_3, 10%);
        }
    }
}
