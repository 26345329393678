html {
    font-size: $html_font_size;
}

body {
    font-family: $font_body;
    line-height: $body_line_height;
    color: $color_text_3;
    letter-spacing: .005em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    margin-bottom: 1.5rem;
}

b,
strong {
    font-weight: 700;
}

label {
    margin-bottom: .8rem;
    font-weight: 700;
    color: $color_dark_3;
}

code:not(.dx-gist) {
    display: inline-block;
    padding: 0 8px;
    font-family: $font_body;
    font-size: 1rem;
    color: #a14a4f;
    background-color: #f7f7f9;
    border-radius: 3px;
}

/* headings */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 1.4rem;
    font-family: $font_headings;
    font-weight: 600;
    color: $color_dark_1;
    text-transform: none;
    letter-spacing: normal;

    a {
        color: $color_main_1;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $color_dark_1;
            text-decoration: none;
        }
    }
}
h1,
.h1 {
    margin-top: -8px;
    font-size: 2.5rem;
}
h2,
.h2 {
    margin-top: -7px;
    font-size: 2.25rem;
}
h3,
.h3 {
    margin-top: -6px;
    font-size: 1.875rem;
}
h4,
.h4 {
    margin-top: -5px;
    font-size: 1.5rem;
}
h5,
.h5 {
    margin-top: -4px;
    font-size: 1.25rem;
}
h6,
.h6 {
    margin-top: -3px;
    font-size: 1.125rem;
}
.display-1,
.display-2,
.display-3,
.display-4 {
    text-transform: none;
    letter-spacing: normal;
}
.display-1 {
    font-size: 4.2rem;
}
.display-2 {
    font-size: 3.75rem;
}
.display-3 {
    font-size: 3rem;
    line-height: 1.15;
}
.display-4 {
    font-size: 3rem;
}


/* Lead */
.lead {
    font-size: 1.125rem;
    font-weight: 400;
}
