.dx-gallery {
    .dx-gallery-item {
        position: relative;
        display: block;
        height: 100%;
        overflow: hidden;

        &:focus {
            outline: none;
        }

        .dx-gallery-item-overlay {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            font-size: 5rem;
            line-height: 0;
            color: #fff;
            background-color: rgba($color_dark_1, .7);
            opacity: 0;
            will-change: opacity;
            transition: opacity .2s ease-in-out;
            z-index: 1;

            > .icon {
                opacity: 0;
                will-change: opacity;
                transition: opacity .2s ease-in-out;
                transition-delay: .1s;
            }

            &.dx-gallery-item-overlay-md {
                font-size: 3rem;

                @media screen and #{$media_xs} {
                    font-size: 2rem;
                }
            }
            &.dx-gallery-item-overlay-sm {
                font-size: 2rem;
            }
        }

        > img {
            width: 100%;
            height: 100%;
            // stylelint-disable-next-line
            font-family: "object-fit: cover";
            object-fit: cover;
            will-change: transform;
            transition: transform .4s ease-in-out;
            transform: scale(1);
        }

        &:hover {
            > img {
                transform: scale(1.02);
            }
            .dx-gallery-item-overlay {
                opacity: 1;

                > .icon {
                    opacity: 1;
                }
            }
        }
    }
}
