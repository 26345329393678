.dx-form {
    .dx-select-ticket {
        padding: 0;
        box-shadow: none;

        select {
            display: none;
            visibility: hidden;
        }

        img {
            width: 30px;
            margin-right: 11px;
            // stylelint-disable-next-line
            font-family: "object-fit: cover";
            object-fit: cover;
        }

        &.show {
            .btn.dropdown-toggle {
                color: darken($color_grey_1, 10%);
                background-color: lighten($color_grey_3, 22%);
            }
        }

        .btn.dropdown-toggle {
            height: 54px;
            padding: 12px 19px;
            color: rgba($color_grey_1, .8);
            background-color: rgba(lighten($color_grey_3, 24%), .8);
            border: 0;
            border-radius: 3px;
            box-shadow: inset 0 0 0 1px rgba(lighten($color_grey_3, 17%), .8);
            opacity: 1;
            transition: background-color .2s ease-in-out, box-shadow .2s ease-in-out, color .2s ease-in-out;

            &:hover,
            &.hover,
            &:focus {
                color: darken($color_grey_1, 10%);
                background-color: lighten($color_grey_3, 22%);
                box-shadow: inset 0 0 0 1px lighten($color_grey_3, 10%);
            }
            &:focus {
                border: 0;
                // stylelint-disable-next-line
                outline: 0 !important;
            }
        }

        .dropdown-menu {
            padding: 0;
            border-color: rgba(lighten($color_grey_3, 17%), .8);
            z-index: 2;

            .inner {
                &::-webkit-scrollbar-track {
                    background-color: #fff;
                }
                &::-webkit-scrollbar {
                    width: 6px;
                    background-color: #fff;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: rgba($color_grey_3, .6);
                    border-radius: 3px;

                    &:hover {
                        background-color: $color_grey_3;
                    }
                }
            }

            .dropdown-item {
                position: relative;
                padding: 10px 19px;
                color: rgba($color_grey_1, .8);
                transition: background-color .15s ease-in-out, color .15s ease-in-out;
                z-index: 2;

                &.active,
                &:focus,
                &:hover {
                    color: darken($color_grey_1, 10%);
                    background-color: lighten($color_grey_3, 22%);
                    outline: none;
                }
            }
            .dropdown-menu {
                > li + li {
                    border-top: 1px solid rgba(#000, .08);
                }
            }
        }
    }
}
