// Container
@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

// vertical gap
.row.vertical-gap > [class*="col"] {
    padding-top: 30px;
}
.row.vertical-gap {
    margin-top: -30px;
}

// Xsmall gap
.row.xs-gap {
    margin-right: -5px;
    margin-left: -5px;
}
.row.xs-gap > [class*="col"] {
    padding-right: 5px;
    padding-left: 5px;
}
.row.xs-gap.vertical-gap > [class*="col"] {
    padding-top: 10px;
}
.row.xs-gap.vertical-gap {
    margin-top: -10px;
}

// small gap
.row.sm-gap {
    margin-right: -10px;
    margin-left: -10px;
}
.row.sm-gap > [class*="col"] {
    padding-right: 10px;
    padding-left: 10px;
}
.row.sm-gap.vertical-gap > [class*="col"] {
    padding-top: 20px;
}
.row.sm-gap.vertical-gap {
    margin-top: -20px;
}

// mid gap
.row.md-gap {
    margin-right: -20px;
    margin-left: -20px;
}
.row.md-gap > [class*="col"] {
    padding-right: 20px;
    padding-left: 20px;
}
.row.md-gap.vertical-gap > [class*="col"] {
    padding-top: 40px;
}
.row.md-gap.vertical-gap {
    margin-top: -40px;
}

// large gap
.row.lg-gap {
    margin-right: -30px;
    margin-left: -30px;
}
.row.lg-gap > [class*="col"] {
    padding-right: 30px;
    padding-left: 30px;
}
.row.lg-gap.vertical-gap > [class*="col"] {
    padding-top: 60px;
}
.row.lg-gap.vertical-gap {
    margin-top: -60px;
}
