@import "variables";

/*------------------------------------------------------------------
  [Typography]

  Body:     #{$html_font_size}/#{$body_line_height} #{$font_body};
  Headings: #{$font_headings}

  Note: Most of font sizes are relative to the base font size (rem)
 -------------------------------------------------------------------*/

/*------------------------------------------------------------------
  [Color codes]

  Main 1:    #{$color_main_1}
  Main 2:    #{$color_main_2}
  Main 3:    #{$color_main_3}

  Dark 1:    #{$color_dark_1}
  Dark 2:    #{$color_dark_2}
  Dark 3:    #{$color_dark_3}
 -------------------------------------------------------------------*/

/*------------------------------------------------------------------
  [Table of contents]
  {{table_of_contents}}
 -------------------------------------------------------------------*/


/*------------------------------------------------------------------

  Base

 -------------------------------------------------------------------*/
@import "parts/base";

/*------------------------------------------------------------------

  Typography

 -------------------------------------------------------------------*/
@import "parts/typography";

/*------------------------------------------------------------------

  Bootstrap

 -------------------------------------------------------------------*/
@import "parts/bootstrap";

/*------------------------------------------------------------------

  Helpers

 -------------------------------------------------------------------*/
@import "parts/helpers";

// Layouts

/*------------------------------------------------------------------

  Navbar

 -------------------------------------------------------------------*/
@import "parts/navbar";

/*------------------------------------------------------------------

  Navbar Fullscreen

 -------------------------------------------------------------------*/
@import "parts/navbar-fullscreen";

/*------------------------------------------------------------------

  Header

 -------------------------------------------------------------------*/
@import "parts/header";

/*------------------------------------------------------------------

  Signin

 -------------------------------------------------------------------*/
@import "parts/signin";

/*------------------------------------------------------------------

  Portfolio

 -------------------------------------------------------------------*/
@import "parts/portfolio";

/*------------------------------------------------------------------

  Reviews

 -------------------------------------------------------------------*/
@import "parts/reviews";

/*------------------------------------------------------------------

  Article

 -------------------------------------------------------------------*/
@import "parts/article";

/*------------------------------------------------------------------

  Footer

 -------------------------------------------------------------------*/
@import "parts/footer";

/*------------------------------------------------------------------

  Widgets

 -------------------------------------------------------------------*/
@import "parts/widgets";

/*------------------------------------------------------------------

  Social Links

 -------------------------------------------------------------------*/
@import "parts/social-links";

/*------------------------------------------------------------------

  Blog

 -------------------------------------------------------------------*/
@import "parts/blog";

/*------------------------------------------------------------------

  Ticket

 -------------------------------------------------------------------*/
@import "parts/ticket";

/*------------------------------------------------------------------

  Topic

 -------------------------------------------------------------------*/
@import "parts/topic";


/*------------------------------------------------------------------

  Elements

 -------------------------------------------------------------------*/

/*---------------------------
    Element Popup
  ----------------------------*/
@import "parts/element-popup";

/*---------------------------
   Element List
  ----------------------------*/
@import "parts/element-list";

/*---------------------------
  Element Buttons
 ----------------------------*/
@import "parts/element-buttons";

/*---------------------------
  Element Breadcrumbs
 ----------------------------*/
@import "parts/element-breadcrumbs";

/*---------------------------
  Element Tabs
 ----------------------------*/
@import "parts/element-tabs";

/*---------------------------
  Element Collapse
 ----------------------------*/
@import "parts/element-collapse";

/*---------------------------
  Element Feature
 ----------------------------*/
@import "parts/element-feature";

/*---------------------------
  Element Content Boxes
 ----------------------------*/
@import "parts/element-content-boxes";

/*---------------------------
  Element Table
 ----------------------------*/
@import "parts/element-table";

/*---------------------------
  Element Pagination
 ----------------------------*/
@import "parts/element-pagination";

/*---------------------------
  Element Blockquotes
 ----------------------------*/
@import "parts/element-blockquotes";

/*---------------------------
  Element Image Boxes
 ----------------------------*/
@import "parts/element-image-boxes";

/*---------------------------
  Element Dropdown
 ----------------------------*/
@import "parts/element-dropdown";

/*---------------------------
  Element Forms
 ----------------------------*/
@import "parts/element-forms";

/*---------------------------
  Element Alert
 ----------------------------*/
@import "parts/element-alert";

/*---------------------------
  Element Changelog
 ----------------------------*/
@import "parts/element-changelog";

/*------------------------------------------------------------------

  Plugins

 -------------------------------------------------------------------*/

/*---------------------------
    Plugin Swiper
 ----------------------------*/
@import "parts/plugin-swiper";

/*---------------------------
    Plugin Bootstrap Select
 ----------------------------*/
@import "parts/plugin-bootstrap-select";

/*---------------------------
    Plugin Quill
 ----------------------------*/
@import "parts/plugin-quill";

/*---------------------------
    Plugin Dropzone
 ----------------------------*/
@import "parts/plugin-dropzone";

a, a:hover,a:visited, a:focus {
  text-decoration:none;
}