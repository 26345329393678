.dx-portfolio-item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    border-radius: 3px;

    &:hover {
        .dx-portfolio-item-overlay {
            visibility: visible;
            opacity: 1;
        }
        .dx-portfolio-item-image img {
            transform: scale(1.02);
        }
    }

    .dx-portfolio-item-overlay {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        visibility: hidden;
        opacity: 0;
        will-change: opacity;
        transition: visibility .2s ease-in-out, opacity .2s ease-in-out;
        z-index: 1;
    }
    .dx-portfolio-item-image {
        position: relative;
        overflow: hidden;

        &::after {
            content: "";
            display: block;
            padding-top: calc(60% - 2px);
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // stylelint-disable-next-line
            font-family: "object-fit: cover";
            object-fit: cover;
            will-change: transform;
            transition: transform .2s ease-in-out;
            transform: scale(1);
            z-index: 0;
        }
    }
    .dx-portfolio-item-info {
        display: flex;
        align-items: stretch;

        &:hover,
        &:focus {
            text-decoration: none;

            .dx-portfolio-item-title {
                color: $color_main_1;
            }
        }
    }
    .dx-portfolio-item-title {
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 96px;
        padding: 24px 0 24px 30px;
        font-weight: 600;
        color: $color_dark_3;
        transition: color .2s ease-in-out;
    }
    .dx-portfolio-item-price {
        display: inline-flex;
        align-items: center;
        padding: 24px 30px 24px 25px;
        font-size: 1.25rem;
        font-weight: 700;
        color: $color_dark_3;
    }
}

// Style 2
.dx-portfolio-item-style-2 {
    flex-direction: row;
    padding: 15px;

    &:hover,
    &:focus {
        text-decoration: none;

        .dx-portfolio-item-title {
            color: $color_main_1;
        }
    }

    .dx-portfolio-item-img {
        width: 80px;
        min-width: 80px;
        height: 70px;
        overflow: hidden;
        border-radius: 3px;

        + .dx-portfolio-item-title {
            margin-left: 25px;
        }

        img {
            max-width: 100%;
        }
    }
    .dx-portfolio-item-title {
        min-height: auto;
        padding: 0;
    }
}
