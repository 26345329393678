.dx-blockquote {
    margin-bottom: 1.5rem;
    font-family: $font_headings;
    font-style: italic;
    font-weight: 600;
    color: $color_dark_1;

    .dx-blockquote-title {
        display: block;
        margin-bottom: 7px;
        font-weight: 600;
        color: $color_dark_1;
    }

    // Style 2
    &.dx-blockquote-style-2 {
        padding: 20px 0 20px 20px;
        font-style: normal;
        font-weight: 400;
        color: $color_text_3;
        border-left: 3px solid #dbdbdb;
    }
}
