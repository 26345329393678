.dx-social-links {
    padding: 0;
    margin: -10px -5px 0;
    font-size: 0;
    list-style-type: none;

    &::after {
        content: "";
        display: table;
        clear: both;
    }

    > li {
        display: inline-block;
        margin: 10px 6px 0;

        > * {
            display: inline-flex;
            align-items: center;
            width: auto;
            min-height: 22px;
            font-size: 1.1rem;
            text-align: center;
            cursor: pointer;
            will-change: opacity;
            transition: opacity .2s ease-in-out, color .2s ease-in-out;

            &,
            &:focus,
            &:active {
                color: #fff;
                opacity: .8;
            }

            &:hover,
            &.hover {
                text-decoration: none;
                opacity: 1;
            }
        }
    }

    @each $name, $color in $colors_social {
        > li > *.dx-social-#{$name} {
            color: $color_grey_1;

            &:hover,
            &.hover {
                color: $color;
            }
        }
    }

    &.dx-social-links-style-2 {
        > li > * {
            padding: 10px 14px;
            font-size: .875rem;
            background-color: rgba(lighten($color_grey_3, 24%), .8);
            border-radius: 3px;
            box-shadow: inset 0 0 0 1px rgba(lighten($color_grey_3, 17%), .8);
            opacity: 1;
            transition: background-color .2s ease-in-out, box-shadow .2s ease-in-out, color .2s ease-in-out;

            &:hover,
            &.hover {
                background-color: lighten($color_grey_3, 22%);
                box-shadow: inset 0 0 0 1px lighten($color_grey_3, 10%);
            }

            > .icon {
                margin-right: 8px;
                font-size: 1.1rem;
            }
            @each $name, $color in $colors_social {
                &.dx-social-#{$name} {
                    color: rgba($color_grey_1, .8);

                    &:hover,
                    &.hover {
                        color: darken($color_grey_1, 10%);
                    }
                }
            }
        }
    }
}
